import React from 'react';
import style from './cases.module.css';
import Page from '../../Components/Page/page';
import Title from '../../Components/Title/title';
import Section from '../../Components/Section/section';
import Button from '../../Components/Button/button';
import ROUTES from '../../Routes/routes';

const Cases = () => {
    return (
        <Page>
          <Section>
            <Title>Cases</Title>
            <p className={style.casesText}>Heb je een case die we samen kunnen uitwerken?</p>
            <Button link={ROUTES.contact}>Deel jouw uitdaging in een vrijblijvend gesprek</Button>
          </Section>
        </Page>
    );
};

export default Cases;