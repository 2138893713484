import React, { useEffect, useState } from 'react';
import style from './header.module.css';
import ROUTES from '../../Routes/routes';
import { Link, useLocation } from 'react-router-dom';
import { firebase } from '../../firebase';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";

const Header = () => {
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const url = await firebase.storage().ref('main/groot.webp').getDownloadURL();
        setLogoUrl(url);
      } catch (error) {
        console.error('Error fetching profile photo:', error);
      }
    };

    fetchLogo();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const headerClassName = `
    ${isScrolled ? style.scrolled : ''} 
    ${location.pathname === ROUTES.contact ? style.greenBackground : ''}
  `;

  return (
    <header className={headerClassName}>
      <nav>
        <a href="/" className={style.name}><img src={logoUrl} alt='logo' className={style.logo} /></a>
        <button className={style.hamburger} onClick={() => setMenuOpen(!menuOpen)}>
          <RxHamburgerMenu color='#668355' size={30}/>
        </button>
        <div className={`${style.navRight} ${menuOpen ? style.showMenu : ''}`}>
          <IoClose color='#668355' size={30} className={style.close} onClick={() => setMenuOpen(false)}/>
          <Link to={ROUTES.home} onClick={() => setMenuOpen(false)} className={location.pathname === ROUTES.home ? style.activeNav : ''}>Home</Link>
          <Link to={ROUTES.aboutme} onClick={() => setMenuOpen(false)} className={location.pathname === ROUTES.aboutme ? style.activeNav : ''}>Over mij</Link>
          <Link to={ROUTES.cases} onClick={() => setMenuOpen(false)} className={location.pathname === ROUTES.cases ? style.activeNav : ''}>Cases</Link>
          <Link to={ROUTES.blogs} onClick={() => setMenuOpen(false)} className={location.pathname === ROUTES.blogs ? style.activeNav : ''}>Blogs</Link>
          <Link to={ROUTES.contact} onClick={() => setMenuOpen(false)} className={location.pathname === ROUTES.contact ? style.activeNav : ''}>Contact</Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;
