// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCU_AmFySn6AY3K94SLHo27Bh2CYz3p_Qk",
  authDomain: "movim-management.firebaseapp.com",
  projectId: "movim-management",
  storageBucket: "movim-management.appspot.com",
  messagingSenderId: "329268345542",
  appId: "1:329268345542:web:5ae3aaebcf7a97268cb5c7",
  measurementId: "G-LSS1YQN5RF"
};

// Initialize Firebase
if (!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}

export { firebase };